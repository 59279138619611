<script setup>
import mushroom from "cem-primary-api";
import toastr from "toastr";
import { useRouter } from "vue-router";
import { FormInstance } from "element-plus";
import { ref, reactive, onMounted } from "vue";
import MethodService from "../../service/MethodService";
import LanguageService from "../../service/LanguageService";

const router = useRouter();
const ruleFormRef = ref(FormInstance);
const loadingBtn = ref(false); // loading của nút đăng nhập
const account = ref("");
// dữ liệu form
let dataForm = reactive({
  value: {
    account: account.value,
    passwordOld: "",
    passwordNew: "",
    passwordCheck: "",
  },
});

// validate form
const validatePass = (rule, value, callback) => {
  if (value.length < 6) {
    callback(new Error(LanguageService?.lang?.t_error_min_password_length ?? "t_error_min_password_length"));
  } else if (value.includes(account.value)) {
    console.log("dataForm.value.accoun", account.value);
    callback(new Error(LanguageService?.lang?.t_error_password_contain_account ?? "t_error_password_contain_account"));
  } else {
    callback();
  }
};

const validatePassCheck = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(LanguageService?.lang?.t_error_re_enter_password ?? "t_error_re_enter_password"));
  } else if (value !== dataForm.value.passwordNew) {
    callback(new Error(LanguageService?.lang?.t_error_password_not_same ?? "t_error_password_not_same"));
  } else {
    callback();
  }
};
const rulesForm = reactive({
  passwordOld: [
    { required: true, trigger: "blur", message: LanguageService?.lang?.t_please_enter_old_password ?? "t_please_enter_old_password" },
  ],
  passwordNew: [
    { required: true, trigger: "blur", message: LanguageService?.lang?.t_please_enter_old_password ?? "t_please_enter_new_password" },
    { validator: validatePass, trigger: "blur" },
  ],
  passwordCheck: [
    { required: true, validator: validatePassCheck, trigger: "blur" },
  ],
});

const back = () => {
  router.go(-1);
};

const resetPassword = async (formEl) => {
  loadingBtn.value = true;
  formEl.validate(async (valid) => {
    if (valid) {
      try {
        await mushroom.$auth.changePasswordAsync(
          account.value,
          dataForm.value.passwordOld,
          dataForm.value.passwordNew
        );
        toastr.success(LanguageService?.lang?.t_reset_password_success ?? "t_reset_password_success");
        setTimeout(() => {
          router.push({ name: "login" });
        }, 2000);
      } catch (e) {
        if (e.code === -1) {
          toastr.error(LanguageService?.lang?.t_old_password_wrong ?? "t_old_password_wrong");
        } else MethodService.showError(e.code);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

onMounted(async () => {
  const response = await mushroom.$auth.meAsync();
  account.value = response.result.account;
});
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div style="font-weight: 600; font-size: 30px">CEM</div>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2 position-relative">
                  <el-button
                    type="button"
                    class="
                      btn btn-soft-secondary
                      waves-effect
                      position-absolute
                    "
                    style="top: -8px; left: 8px; display: flex"
                    @click="back()"
                  >
                    <i
                      class="ri-arrow-go-back-line"
                      style="padding-right: 5px"
                    ></i>
                    {{ $t("t-back") }}
                  </el-button>
                  <h5 class="text-primary" style="font-weight: 600">
                    {{ $t("t-reset-password") }}
                  </h5>
                </div>
                <div class="p-2 mt-4">
                  <el-form
                    ref="ruleFormRef"
                    :model="dataForm.value"
                    status-icon
                    :rules="rulesForm"
                    label-width="150px"
                    label-position="top"
                    @submit.prevent
                  >
                    <el-form-item :label="$t('t-old-password')" prop="passwordOld">
                      <el-input
                        type="password"
                        autocomplete="off"
                        v-model="dataForm.value.passwordOld"
                        show-password
                        tabIndex="1"
                      />
                    </el-form-item>
                    <el-form-item :label="$t('t-new-password')" prop="passwordNew">
                      <el-input
                        type="password"
                        autocomplete="off"
                        v-model="dataForm.value.passwordNew"
                        show-password
                        tabIndex="2"
                      />
                    </el-form-item>
                    <el-form-item
                      :label="$t('t-new-password-again')"
                      prop="passwordCheck"
                    >
                      <el-input
                        type="password"
                        autocomplete="off"
                        v-model="dataForm.value.passwordCheck"
                        show-password
                        tabIndex="3"
                      />
                    </el-form-item>

                    <el-form-item class="mb-0">
                      <el-button
                        type="button"
                        class="btn btn-success w-100"
                        @click="resetPassword(ruleFormRef)"
                        tabindex="4"
                        native-type="submit"
                        :loading="loadingBtn"
                      >
                        {{ $t("t-reset-password") }}
                      </el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.el-button > span {
  display: unset;
  align-items: unset;
}
</style>